import React, { useState,useEffect } from 'react';
import {Document, Page, View,Text, PDFViewer,Image, StyleSheet, pdf} from '@react-pdf/renderer';
import ImagenQR from './tools/imagenQR';
import Logo from './imagenes/escudoIseP.png'
import escPol from './imagenes/escPol.png'
import escEsp from './imagenes/escEsp.png'
import escSup from './imagenes/escSup.png'
import escInv from './imagenes/escInv.png'
import logoProvincia from './imagenes/logoProvincia2024.jpg'
import textoIsep from './imagenes/textoIsep2024.jpg'
import axios from 'axios';
import DameToken from './tools/dameToken.js'
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
function Certificado() {
  const { width, height } = useWindowSize();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [muestro,setMuestro]=useState(false)
  const [datosVarios,setDatosVarios]=useState(false)
  const consulta = useQuery();
  const certificadoModel=(curso, certificado)=>
    {
     
    const certificadoFinal={
    "dniCertificado":certificado.dniCertificado,
    "apellidoNombre":certificado.apellidoNombre,
    "tituloCertificado":curso.tituloCertificado,
    "ciudadCertificado":curso.ciudadCertificado,
    "fechaciudadCertificado":curso.fechaciudadCertificado,
    "escudo1":curso.escudo1,
    "escudo2":curso.escudo2,
    "fechacursado":curso.fechacursado,
    "cantidadHoras":curso.cantidadHoras,
    "tipoHoras":curso.tipoHoras,
    "sedeCertificado":certificado.sedeCertificado,
    "resolucionCertificado":curso.resolucionCertificado,
    "grado":certificado.grado,
    "reginterno":curso.reginterno,
    "cantidadFirmas":curso.cantidadFirmas,
    "firma1":curso.firma1,        
    "firmante1":curso.firmante1,
    "firmante1jer":curso.firmante1jer,
    "firmante1cargo":curso.firmante1cargo, 
    "firma2":curso.cantidadFirmas>1?curso.firma2:"",
    "firmante2":curso.cantidadFirmas>1?curso.firmante2:"",
    "firmante2jer":curso.cantidadFirmas>1?curso.firmante2jer:"",
    "firmante2cargo":curso.cantidadFirmas>1?curso.firmante2cargo:"",  
    "firma3":curso.cantidadFirmas>2?curso.firma3:"", 
    "firmante3":curso.cantidadFirmas>2?curso.firmante3:"",
    "firmante3jer":curso.cantidadFirmas>2?curso.firmante3jer:"",
    "firmante3cargo":curso.cantidadFirmas>2?curso.firmante3cargo:"",
    "firma4":curso.cantidadFirmas>3?curso.firma4:"",
    "firmante4":curso.cantidadFirmas>3?curso.firmante4:"",
    "firmante4jer":curso.cantidadFirmas>3?curso.firmante4jer:"",
    "firmante4cargo":curso.cantidadFirmas>3?curso.firmante4cargo:"",
    "firma5":curso.cantidadFirmas>4?curso.firma5:"",
    "firmante5":curso.cantidadFirmas>4?curso.firmante5:"",
    "firmante5jer":curso.cantidadFirmas>4?curso.firmante5jer:"",
    "firmante5cargo":curso.cantidadFirmas>4?curso.firmante5cargo:""    
    }
    setDatosVarios(certificadoFinal)
    setMuestro(true)
    }
 
  function formateeFecha(date) {
    const meses = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
        'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    const dia = date.getUTCDate();
    const mes = meses[date.getUTCMonth()];
    const año = date.getUTCFullYear();

    return `${dia} de ${mes} de ${año}`;
}
function formateeFechaSoloMesYAno(date) {
  const meses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
      'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  const año = date.getUTCFullYear();
  const mes = meses[date.getUTCMonth()];
  

  return `${mes} de ${año}`;
}
function strigAFecha(dateString) {

  const date = new Date(dateString);


  if (isNaN(date)) {
      throw new Error('Fecha inválida');
  }

  return date;
}

  
const direccion="30548471"



const obtieneCertificado=async()=>{
  const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/certificados/api-devuelveCertificados?ni="+consulta.get("ni")+"&curso="+consulta.get('curso')+"&Ra@="+DameToken()
      });
      try {
       const datos= await client.post('/')
       if(datos)
        {
       certificadoModel(datos.data.Curso,datos.data.cursante)
       setMuestro(true)
       }
        }
      catch (error) {}
 
}



useEffect(() => {

  obtieneCertificado()
  return () => {
    
  };
}, []);





// Crear el documento PDF
const MyDocument = () => (
  <Document  title={"Certificado "+datosVarios.apellidoNombre} onLoadError={console.error}>
        <Page size="A4" orientation='landscape' >

        <View id="Logos"style={{ width:"100%", marginTop:"20px", padding:"1px"}}>
        <Image style={{width:"350px", position:"relative" }}src={logoProvincia}/> 
        <Image style={{width:"300px", position:"absolute", alignSelf:"flex-end" }} src={textoIsep}/>
      
       </View>
       
        
        <View className='datosPersonales_legajo' style={{textAlign:"center",margin:"55px",paddingTop:"1px",}}>

    <Text className='sub_titulo_legajo'>Se le expide el presente certificado a</Text>
    <Text style={{ fontSize:"32px"}} >{datosVarios.apellidoNombre+""} N.I. N° {datosVarios.dniCertificado}</Text>
    <Text style={{fontSize:"20px", fontStyle:"oblique"}}>Por haber finalizado satisfactoriamente las exigencias del curso extracurricular</Text>
    <Text>de </Text>
    <Text style={{ alignSelf:"center",  width:"500px", marginTop:"10px", fontSize:"25"}}>{datosVarios.tituloCertificado}</Text>
    <Text style={{fontSize:"15px", alignSelf:"flex-end", marginTop:"180px",position:"absolute"}}>{datosVarios.ciudadCertificado+", "+formateeFecha(strigAFecha(datosVarios.fechaciudadCertificado))}</Text>
    <View id="Escudo"style={{ alignSelf:"center",  marginTop:"10px"}}>
     {datosVarios.escudo2!="none"?
     <>
     <View id="Escudo"style={{ alignSelf:"center",  position:"absolute", marginTop:"10px"}}><Image style={{width:"80px", position:"absolute", marginLeft:"-80px"}} src={Logo}/></View>
     
     {datosVarios.escudo2=="escEsp"?(<View id="Escudo"style={{ alignSelf:"center",  position:"absolute", marginTop:"10px"}}><Image style={{width:"65px", position:"absolute", marginLeft:"20px"}} src={escEsp}/></View>):""}
     {datosVarios.escudo2=="Logo"?(<View id="Escudo"style={{ alignSelf:"center",  position:"absolute", marginTop:"10px"}}><Image style={{width:"65px", position:"absolute", marginLeft:"20px"}} src={Logo}/></View>):""}
     {datosVarios.escudo2=="escSup"?(<View id="Escudo"style={{ alignSelf:"center", height:"10%", width:"10%", marginTop:"10px"}}><Image style={{width:"65px", position:"absolute", marginLeft:"20px"}} src={escSup}/></View>):""}
     {datosVarios.escudo2=="escInv"?(<View id="Escudo"style={{ alignSelf:"center",  marginTop:"10px"}}><Image style={{width:"65px", position:"absolute", marginLeft:"20px"}} src={escInv}/></View>):""}
     {datosVarios.escudo2=="escPol"?(<View id="Escudo"style={{ alignSelf:"center",  marginTop:"10px"}}><Image style={{width:"80px", position:"absolute", marginLeft:"20px",marginTop:"5px"}} src={escPol}/></View>):""}
     </>:
     
     <>    <View id="Escudo"style={{ alignSelf:"center",  position:"absolute", marginTop:"10px"}}>
      <Image style={{width:"120px", position:"absolute", marginLeft:"-55px"}} src={Logo}/></View>
</>} 

      </View>
    
    <View style={{ alignSelf:"center",  marginTop:"40px"}}>
      
      
      {//FIRMAS //////////////////////////////////////////////////////////////////////////////////////////
      }
      {datosVarios.cantidadFirmas==1?<>
      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-60px"}}src={datosVarios.firma1}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-60px"}}>
      <Text >{datosVarios.firmante1}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante1jer}</Text>
      <Text >{datosVarios.firmante1cargo}</Text>
      </View>
        </>:""}


      {datosVarios.cantidadFirmas==2?<>
      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-250px"}}src={datosVarios.firma2}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-250px"}}>
      <Text >{datosVarios.firmante2}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante2jer}</Text>
      <Text >{datosVarios.firmante2cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"121px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"125px"}} src={datosVarios.firma1}/> </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute", marginTop:"210px", marginLeft:"150px"}}>
      <Text >{datosVarios.firmante1}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante1jer}</Text>
      <Text >{datosVarios.firmante1cargo}</Text>
      </View> 
      </>:""}

  
      {datosVarios.cantidadFirmas==3?<>
      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-280px"}}src={datosVarios.firma2}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-280px"}}>
      <Text >{datosVarios.firmante2}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante2jer}</Text>
      <Text >{datosVarios.firmante2cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-60px"}}src={datosVarios.firma1}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-60px"}}>
      <Text >{datosVarios.firmante1}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante1jer}</Text>
      <Text >{datosVarios.firmante1cargo}</Text>
      </View>

        <View style={{ alignSelf:"center",  position:"absolute", marginTop:"121px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"155px"}} src={datosVarios.firma3}/> </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute", marginTop:"210px", marginLeft:"180px"}}>
      <Text >{datosVarios.firmante3}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante3jer}</Text>
      <Text >{datosVarios.firmante3cargo}</Text>
      </View> 
        </>:""}
        
      {datosVarios.cantidadFirmas==4?<>
      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-350px"}}src={datosVarios.firma1}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-350px"}}>
      <Text >{datosVarios.firmante1}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante1jer}</Text>
      <Text >{datosVarios.firmante1cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-130px"}}src={datosVarios.firma2}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-130px"}}>
      <Text >{datosVarios.firmante2}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante2jer}</Text>
      <Text >{datosVarios.firmante2cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"100px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"40px"}}src={datosVarios.firma3}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute",  marginTop:"210px", marginLeft:"70px"}}>
      <Text >{datosVarios.firmante3}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante3jer}</Text>
      <Text >{datosVarios.firmante3cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"121px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"210px"}} src={datosVarios.firma4}/> </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"absolute", marginTop:"210px", marginLeft:"240px"}}>
      <Text >{datosVarios.firmante4}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante4jer}</Text>
      <Text >{datosVarios.firmante4cargo}</Text>
      </View> 
      
        </>:""}


      {datosVarios.cantidadFirmas==5?<>
      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-380px"}}src={datosVarios.firma4}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"9px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-330px"}}>
      <Text >{datosVarios.firmante4}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante4jer}</Text>
      <Text >{datosVarios.firmante4cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-200px"}}src={datosVarios.firma2}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"9px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-200px"}}>
      <Text >{datosVarios.firmante2}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante2jer}</Text>
      <Text >{datosVarios.firmante2cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"-60px"}}src={datosVarios.firma1}/> 
      </View>   
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"9px" ,  position:"absolute",  marginTop:"210px", marginLeft:"-60px"}}>
      <Text >{datosVarios.firmante1}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante1jer}</Text>
      <Text >{datosVarios.firmante1cargo}</Text>
      </View>

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"90px"}} src={datosVarios.firma3}/> </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"9px" ,  position:"absolute", marginTop:"210px", marginLeft:"110px"}}>
      <Text >{datosVarios.firmante3}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante3jer}</Text>
      <Text >{datosVarios.firmante3cargo}</Text>
      </View> 

      <View style={{ alignSelf:"center",  position:"absolute", marginTop:"120px"}}>
      <Image style={{width:"140px", position:"absolute", marginLeft:"240px"}} src={datosVarios.firma5}/> </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"9px" ,  position:"absolute", marginTop:"210px", marginLeft:"270px"}}>
      <Text >{datosVarios.firmante5}</Text>
      <Text style={{textAlign:"center"}}>{datosVarios.firmante5jer}</Text>
      <Text >{datosVarios.firmante5cargo}</Text>
      </View> 
        </>:""}
      
  
     

      </View>
    
      
    <View style={{alignSelf:"flex-start", fontSize:"08px" ,marginTop:"20%", position:"absolute"}}> <Text style={{transform: "rotate(270deg)",alignSelf:"left", marginLeft:"-100px"}}>Reg Interno:{datosVarios.reginterno} </Text>
    <View id="QR"style={{ alignSelf:"center", marginLeft:"80px", marginBottom:"-350px"}}>       
    <ImagenQR direccion={direccion} tamaño="120"/></View>
    </View>
    <View style={{marginVertical:"5%", position:"relative"}}>
    <View style={{ alignSelf:"flex-end",fontSize:"8px", marginTop:"35px",position:"absolute"}}>
      <Text>Cursado: {formateeFechaSoloMesYAno(strigAFecha(datosVarios.fechacursado))}</Text>
      <Text>Horas:{datosVarios.cantidadHoras + " "+datosVarios.tipoHoras}</Text>
      <Text>Sede:{datosVarios.sedeCertificado}</Text>
      <Text>Resolución:{datosVarios.resolucionCertificado}</Text>
      <Text>Grado:{datosVarios.grado}</Text>
      </View>
      </View>
    </View>

        </Page>
      </Document>
);

  const generatePdfDocument = async () => {
   try {
    const nombreArchivo="Certificado "+datosVarios.apellidoNombre
    const doc =<MyDocument/>
    const blob = await pdf(doc).toBlob();
    saveAs(blob, nombreArchivo);
   } catch (error) {
    
   } 
  };
// Componente principal del visor PDF
return (<>
{muestro?<>

  
      {width<480?<><div style={{textAlign:"center"}}>
        <img src={Logo} style={{width:"100px"}}></img>
        <h1>Descargue su certificado {datosVarios.apellidoNombre}</h1>
        <button className="btn btn-primary"onClick={generatePdfDocument}>DESCARGAR</button></div></>:<><PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
      <MyDocument />
      </PDFViewer></>}

  
</>:<h1>Cargando...</h1>}

      
      </>

    );
  }
export default Certificado
