import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import GeneraCertificado from './GeneraCertificado'
import GeneraCredencial from './GeneraCredencial'
import Display from'./display'
import 'bootstrap/dist/css/bootstrap.min.css' 
function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Display  />}/>
            <Route path='/imprimeCertificado'element={<GeneraCertificado/>}/>
            <Route path='/imprimeCredencial'element={<GeneraCredencial/>}/>
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
