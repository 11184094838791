import React, { useState,useEffect } from 'react';
import {Document, Page, View,Text, PDFViewer,Image, StyleSheet, pdf} from '@react-pdf/renderer';
import ImagenQR from './tools/imagenQR';
import Logo from './imagenes/escudoIseP.png'
import Credencial from './imagenes/credencialProceso.png'
import logoProvincia from './imagenes/logoProvincia2024.jpg'
import textoIsep from './imagenes/textoIsep2024.jpg'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
function GeneraCredencial() {
  const { width, height } = useWindowSize();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [muestro,setMuestro]=useState(false)
  const [datosVarios,setDatosVarios]=useState(false)
  const [procesando,setProcesando]=useState(false)
  const consulta = useQuery();
  
const[postulante,setPostulante]=useState("")
 
  function formateeFecha(date) {
    const meses = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
        'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    const dia = date.getUTCDate();
    const mes = meses[date.getUTCMonth()];
    const año = date.getUTCFullYear();

    return `${dia} de ${mes} de ${año}`;
}
function formateeFechaSoloMesYAno(date) {
  const meses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
      'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  const año = date.getUTCFullYear();
  const mes = meses[date.getUTCMonth()];
  

  return `${mes} de ${año}`;
}
function strigAFecha(dateString) {

  const date = new Date(dateString);


  if (isNaN(date)) {
      throw new Error('Fecha inválida');
  }

  return date;
}

  
const direccion=postulante.id+"-"+postulante.dni


const obtieneCertificado=async()=>{
  const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/procesos/devuelvePostulante/"
      });
      try {
      
       const datos= await client.post('/'+consulta.get("dni")+"?idProceso=proceso_2025-2026")
       if(datos.data.length>0)
        {
         
     setPostulante(datos.data[0])
       setMuestro(true)
       }else{ alert("No hay resultados con ese dni, comuniquese con areainscripcionalumnado@isepsantafe.edu.ar");}
        }
      catch (error) {}
 
}



useEffect(() => {

  obtieneCertificado()
  return () => {
    
  };
}, []);





// Crear el documento PDF
const MyDocument = () => (
  <Document  title={"Credencial Proceso "+postulante.dni} onLoadError={console.error}>
        <Page size="A4"  >

        <View id="Logos"style={{ width:"100%", marginTop:"20px", padding:"1px"}}>
        <Image style={{width:"250px", position:"relative" }}src={logoProvincia}/> 
        <Image style={{width:"200px", position:"absolute", alignSelf:"flex-end" }} src={textoIsep}/>
      
       </View>
       
        
        <View className='datosPersonales_legajo' style={{textAlign:"center",margin:"55px",paddingTop:"1px",}}>

    <Text style={{fontSize:"25px"}}className='sub_titulo_legajo'>Credencial de Proceso de Selección</Text>

    <Image style={{width:"350px",marginLeft:"70px",marginTop:"120px", position:"absolute" }}src={Credencial}/> 
    <Text style={{ marginTop:"266px", marginLeft:"120px",fontSize:"20px",position:"absolute"}}className='sub_titulo_legajo'>{postulante.dni}</Text>
    <Text style={{ marginTop:"290px", marginLeft:"120px",fontSize:"10px",position:"absolute"}}className='sub_titulo_legajo'>{"000-"+postulante.id}</Text>
    <Text style={{ marginTop:"302px", marginLeft:"155px",fontSize:"10px",position:"absolute"}}className='sub_titulo_legajo'>{postulante.apellido+", "+postulante.nombres}</Text>
    <View id="QR"style={{ marginTop:"139px", marginLeft:"240px",  position:"absolute"}}>       
    
    <ImagenQR direccion={direccion} tamaño="495"/></View>
    <Text style={{fontSize:"5px",transform: "rotate(270deg)",alignSelf:"left", marginLeft:"354px",marginTop:"210px",  position:"absolute"}}>La presente no constituye certificación de identidad.</Text>
    <Text style={{fontSize:"15px", fontStyle:"oblique"}}>Por favor recorte por la linea indicada y conserve la presente credencial de proceso de selección para su identificación en cada una de las etapas</Text>
    

    

      
      
     
    
      

    <View style={{marginVertical:"5%", position:"relative"}}>
    
      </View>
    </View>

        </Page>
      </Document>
);

  const generatePdfDocument = async (dni) => {
   try {
    setProcesando(true)
    const nombreArchivo="Credencial Postulante "+dni
    const doc =<MyDocument/>
    const blob = await pdf(doc).toBlob();
    saveAs(blob, nombreArchivo)
    setProcesando(false)
    
   } catch (error) {
    
   } 
  };
  
// Componente principal del visor PDF
return (<>
{muestro?<>

  
      {width<480?<><div style={{textAlign:"center"}}>
        <img src={Logo} style={{width:"100px"}}></img>
        <h1>Descargue su Credencial del proceso 2025-2026</h1>
        <h4>Postulante {postulante.apellido}</h4>
        {procesando?<button className="btn btn-primary" disabled onClick={()=>generatePdfDocument(postulante.dni)}>DESCARGAR</button>:<button className="btn btn-primary" onClick={()=>generatePdfDocument(postulante.dni)}>DESCARGAR</button>}</div></>:<><PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
      <MyDocument />
      </PDFViewer></>}

  
</>:<h1>Cargando...</h1>}

      
      </>

    );
  }
export default GeneraCredencial
