import { useLocation } from 'react-router-dom';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import DameToken from './tools/dameToken.js'
import escudo from './imagenes/escudoIseP.png'
import Button from 'react-bootstrap/Button';
function Display() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    
    const [muestro,setMuestro]=useState(false)
    const [datosVarios,setDatosVarios]=useState(false)
    const[integridad,setIntegridad]=useState(false)
    const[mensaje,setMensaje]=useState("Cargando...")
    const consulta = useQuery();
    
    const verificoIntegridad=()=>{
      
        if(consulta.get("curso")&&consulta.get("ni")>400000){

            setIntegridad(true)
        }
      }
      const certificadoModel=(curso, certificado)=>
        {
         
        const certificadoFinal={
        "dniCertificado":certificado.dniCertificado,
        "apellidoNombre":certificado.apellidoNombre,
        "tituloCertificado":curso.tituloCertificado,
        "ciudadCertificado":curso.ciudadCertificado,
        "fechaciudadCertificado":curso.fechaciudadCertificado,
        "escudo1":curso.escudo1,
        "escudo2":curso.escudo2,
        "fechacursado":curso.fechacursado,
        "cantidadHoras":curso.cantidadHoras,
        "tipoHoras":curso.tipoHoras,
        "sedeCertificado":certificado.sedeCertificado,
        "resolucionCertificado":curso.resolucionCertificado,
        "grado":certificado.grado,
        "reginterno":curso.reginterno,
        "cantidadFirmas":curso.cantidadFirmas,
        "firma1":curso.firma1,        
        "firmante1":curso.firmante1,
        "firmante1jer":curso.firmante1jer,
        "firmante1cargo":curso.firmante1cargo, 
        "firma2":curso.cantidadFirmas>1?curso.firma2:"",
        "firmante2":curso.cantidadFirmas>1?curso.firmante2:"",
        "firmante2jer":curso.cantidadFirmas>1?curso.firmante2jer:"",
        "firmante2cargo":curso.cantidadFirmas>1?curso.firmante2cargo:"",  
        "firma3":curso.cantidadFirmas>2?curso.firma3:"", 
        "firmante3":curso.cantidadFirmas>2?curso.firmante3:"",
        "firmante3jer":curso.cantidadFirmas>2?curso.firmante3jer:"",
        "firmante3cargo":curso.cantidadFirmas>2?curso.firmante3cargo:"",
        "firma4":curso.cantidadFirmas>3?curso.firma4:"",
        "firmante4":curso.cantidadFirmas>3?curso.firmante4:"",
        "firmante4jer":curso.cantidadFirmas>3?curso.firmante4jer:"",
        "firmante4cargo":curso.cantidadFirmas>3?curso.firmante4cargo:"",
        "firma5":curso.cantidadFirmas>4?curso.firma5:"",
        "firmante5":curso.cantidadFirmas>4?curso.firmante5:"",
        "firmante5jer":curso.cantidadFirmas>4?curso.firmante5jer:"",
        "firmante5cargo":curso.cantidadFirmas>4?curso.firmante5cargo:""    
        }
        setDatosVarios(certificadoFinal)
        setMuestro(true)
        }
      const obtieneCertificado=async()=>{
        const client = axios.create({
            baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/certificados/api-devuelveCertificados?ni="+consulta.get("ni")+"&curso="+consulta.get('curso')+"&Ra@="+DameToken()
            });
            try {
             const datos= await client.post('/')
             if(datos.data)
              {console.log(datos.data)
                if(datos.data.Cursante!="Nada que mostrar"){
                    certificadoModel(datos.data.Curso,datos.data.cursante)
                    setMuestro(true)
                }
             
             else{
                setMuestro(false)
                setMensaje("Datos no VALIDOS")
             }}
              }
            catch (error) {}
       
      }
      
      useEffect(() => {
      
       if(  verificoIntegridad()){
        obtieneCertificado()
       }
       obtieneCertificado()
        return () => {
          
        };
      }, []);
const imrpime=()=>{
    window.open('/imprimeCertificado?ni='+consulta.get("ni")+'&curso='+consulta.get("curso"))
}
  return (
    <>{integridad?
    <>{muestro?
    <div style={{"textAlign":"center"}}>
        <img src={escudo} width={"100px"}/>
        <h1>El Instituto de Seguridad Pública de la Provincia de Santa Fe</h1>
           <h3 style={{"color":"green"}}>Ha VERIFICADO CORRECTAMENTE el presente certificado</h3>
           <h4>Cursante: {datosVarios.apellidoNombre}</h4>
           <h4>Identificación bajo número{" "+datosVarios.dniCertificado+" del curso "+datosVarios.tituloCertificado}</h4>
         
           <h4>Carga Horaria: {datosVarios.cantidadHoras + " horas " +datosVarios.tipoHoras}</h4>

           {console.log(datosVarios)}
           <Button variant="success" size="lg"onClick={()=>imrpime()}>Imprimirlo o descargarlo</Button>
            </div>:
            <div>
<h1 style={{textAlign:"center",alignSelf:"center",alignContent:"center",marginTop:"250px"}}>{mensaje}</h1>
            </div>
           }</>:"Consulta no autorizada!!"}
    
   
    </>
  );
}

export default Display;